import React from "react"

const KudoCons = ({ kudoConsCount }) => {
	return (
		<div className="right-list d-flex align-items-center">
			<i className="bx bx-dollar-circle dollar-icon-uesr"></i> &nbsp;
			{kudoConsCount < 0 ? 0 : kudoConsCount}
		</div>
	)
}

export default KudoCons
