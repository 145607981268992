import { useEffect, useState } from "react"
import {
	TabContent,
	TabPane,
	Nav,
	NavItem,
	NavLink,
	Col,
	ListGroup,
	ListGroupItem,
	Row,
	FormGroup,
	Input,
} from "reactstrap"
import axiosAuthHttpMiddelware from "../../common/axiosAuthHttpMiddelware"
import { useHistory } from "react-router-dom"
import classnames from "classnames"
import "./ranking.scss"
// import setLoadFilter
import { loadFilterSwitch as loadFilterSwitchAction } from "../../store/actions/appActions"
import { withContext } from "context"
import Sentiment from "components/Common/Sentiment"
import KudoCons from "components/Common/KudoCons"

const Ranking = ({ loadFilterSwitch, loadFilter }) => {
	const history = useHistory()
	const [trendingSymbols, setTrendingSymbols] = useState()
	const [kudosCoinsSymbols, setKudosCoinsSymbols] = useState()
	const [sentimentSymbols, setSentimentSymbols] = useState()
	const [industryTrending, setIndustryTrending] = useState()
	const [industryKudosCoins, setIndustryKudosCoins] = useState()
	const [industrySentiment, setIndustrySentiment] = useState()
	const [sectorTrending, setSectorTrending] = useState()
	const [sectorKudosCoins, setSectorKudosCoins] = useState()
	const [sectorSentiment, setSectorSentiment] = useState()
	const [activeTab, setActiveTab] = useState("1")
	const [techFund, setTechFund] = useState(
		localStorage.getItem("trendingSectorTechFund") ?? "All"
	)
	const [filterTime, setFilterTime] = useState("1")
	const [filterType, setFilterType] = useState("Stocks")
	const [loading, setLoading] = useState(false)
	const [tooltipData, setTooltipData] = useState(null)
	const [centimentSort, setCentimentSort] = useState(1)

	//on load set the default values
	useEffect(() => {
		if (localStorage.getItem("trendingSectorFilterType")) {
			setFilterType(localStorage.getItem("trendingSectorFilterType"))
		}
		if (localStorage.getItem("trendingSectorFilterTime")) {
			setFilterTime(localStorage.getItem("trendingSectorFilterTime"))
		}
		if (localStorage.getItem("trendingSectorTechFund")) {
			setTechFund(localStorage.getItem("trendingSectorTechFund"))
		}
		if (localStorage.getItem("trendingSectorTab")) {
			setActiveTab(localStorage.getItem("trendingSectorTab"))
		}
	}, [])
	useEffect(() => {
		getTrendingSymbol()
	}, [techFund, filterTime])
	const symboal = params => {
		history.push(`/feed/${params}`)
	}
	const getTrendingSymbol = async () => {
		setLoading(true)
		axiosAuthHttpMiddelware
			.get("/getSymbolList", {
				params: {
					tag: techFund,
					time: filterTime,
				},
			})
			.then(response => {
				if (response.status == 200) {
					setTrendingSymbols(response.data.symbolResponse)
					setKudosCoinsSymbols(response.data.kudosCoinsResponse)
					setSentimentSymbols(response.data.sentimentResponse)
					setIndustryTrending(response.data.industryTrendingResponse)
					setIndustryKudosCoins(response.data.industryKudosCoinsResponse)
					setIndustrySentiment(response.data.industrySentimentResponse)
					setSectorTrending(response.data.sectorTrendingResponse)
					setSectorKudosCoins(response.data.sectorKudosCoinsResponse)
					setSectorSentiment(response.data.sectorSentimentResponse)
					setLoading(false)
				} else {
					setTrendingSymbols([])
					setKudosCoinsSymbols([])
					setSentimentSymbols([])
					setLoading(false)
				}
			})
			.catch(err => {
				console.log(err)
				setLoading(false)
			})
	}

	const toggle = tab => {
		if (activeTab !== tab) {
			setActiveTab(tab)
			localStorage.setItem("trendingSectorTab", tab)
		}
	}

	const symbols = symbolsList => {
		if (symbolsList && symbolsList.length > 0) {
			let tab1Counter = 0
			let tab2Counter = 0
			let tab3Counter = 0
			return symbolsList
				.filter(symbol => {
					if (filterType === "Stocks") {
						return !symbol.symbolname.includes("^") && symbol.type !== "ETF"
					} else if (filterType === "Indexes") {
						return symbol.symbolname.includes("^") && symbol.type !== "ETF"
					} else if (filterType === "ETF") {
						return symbol.type === "ETF"
					} else {
						return true
					}
				})
				.slice(0, 5)
				.map((symbol, index) => {
					if (activeTab === "1") {
						tab1Counter = tab1Counter + 1
					}
					if (activeTab === "2") {
						tab2Counter = tab2Counter + 1
					}
					if (activeTab === "3") {
						tab3Counter = tab3Counter + 1
					}
					return (
						<div key={index}>
							<ListGroup>
								<ListGroupItem
									className="ranking-list fl-list-group"
									role="button"
									onClick={() => {
										if (
											filterType == "Stocks" ||
											filterType == "Indexes" ||
											filterType == "ETF"
										) {
											symboal(symbol.symbolname.replace("$", ""))
										} else {
											if (filterType == "Sectors") {
												localStorage.setItem("trendingSector", symbol.sector)
												localStorage.removeItem("trendingIndustry")
												loadFilterSwitch()
											}
											if (filterType == "Industry") {
												localStorage.setItem(
													"trendingIndustry",
													symbol.industry
												)
												localStorage.removeItem("trendingSector")
												loadFilterSwitch()
											}
											history.push(`/trending`)
										}
									}}
									// on hover tooltip
									style={{ cursor: "pointer" }}
									onMouseEnter={() => {
										if (document.getElementById("SymbolTooltip" + index)) {
											document.getElementById(
												"SymbolTooltip" + index
											).style.display = "block"
											setTooltipData(null)
											axiosAuthHttpMiddelware
												.post("/symbolQuoteSummary", {
													symbolname: symbol.symbolname.replace("$", ""),
													modules: "assetProfile, price",
												})
												.then(response => {
													if (
														response.status == 200 &&
														response.data.symbolResponse[0]
													) {
														setTooltipData(response.data.response)
													}
												})
												.catch(err => {
													console.log(err)
												})
										}
									}}
									onMouseLeave={() => {
										if (document.getElementById("SymbolTooltip" + index)) {
											document.getElementById(
												"SymbolTooltip" + index
											).style.display = "none"
											setTooltipData(null)
										}
									}}>
									{(filterType == "Stocks" || filterType == "Indexes") && (
										<div
											id={"SymbolTooltip" + index}
											className="symbol-tooltip"
											style={{ display: "none" }}>
											<div className="symbol-tooltip-content">
												<div className="symbol-tooltip-title">
													<b>
														{(filterType == "Stocks" ||
															filterType == "Indexes") &&
															symbol.symbolname}
													</b>
													<b>{filterType == "Industry" && symbol.industry}</b>
													<b>{filterType == "Sectors" && symbol.sector}</b>
													<b>{filterType == "ETF" && symbol.symbolname}</b>
												</div>
												<div className="symbol-tooltip-body">
													{tooltipData ? (
														<>
															<div className="symbol-tooltip-body-title">
																{tooltipData.name}
															</div>
															<div className="symbol-tooltip-body-content">
																<div className="symbol-tooltip-body-content-title">
																	{tooltipData.industry}
																</div>
															</div>
														</>
													) : (
														<div className="d-flex justify-content-center">
															<div className="spinner-border" role="status">
																<span className="visually-hidden">
																	Loading...
																</span>
															</div>
														</div>
													)}
												</div>
											</div>
										</div>
									)}

									{activeTab === "1" && (
										<div className="left-list">
											<span>{tab1Counter}</span>{" "}
											<b>
												{(filterType == "Stocks" || filterType == "Indexes") &&
													symbol.symbolname}
											</b>
											<b>{filterType == "Industry" && symbol.industry}</b>
											<b>{filterType == "Sectors" && symbol.sector}</b>
											<b>{filterType == "ETF" && symbol.symbolname}</b>
										</div>
									)}
									{activeTab === "2" && (
										<div className="left-list">
											<span>{tab2Counter}</span>{" "}
											<b>
												{(filterType == "Stocks" || filterType == "Indexes") &&
													symbol.symbolname}
											</b>
											<b>{filterType == "Industry" && symbol.industry}</b>
											<b>{filterType == "Sectors" && symbol.sector}</b>
											<b>{filterType == "ETF" && symbol.symbolname}</b>
										</div>
									)}
									{activeTab === "3" && (
										<div className="left-list">
											<span>{tab3Counter}</span>{" "}
											<b>
												{(filterType == "Stocks" || filterType == "Indexes") &&
													symbol.symbolname}
											</b>
											<b>{filterType == "Sectors" && symbol.sector}</b>
											<b>{filterType == "Industry" && symbol.industry}</b>
											<b>{filterType == "ETF" && symbol.symbolname}</b>
										</div>
									)}
									{(filterType == "Stocks" ||
										filterType == "Indexes" ||
										filterType == "ETF") &&
										activeTab === "1" && (
											<div className="right-list">{symbol.symbolcount}</div>
										)}
									{filterType == "Sectors" && activeTab === "1" && (
										<div className="right-list">{symbol.csymbolcount}</div>
									)}
									{filterType == "Industry" && activeTab === "1" && (
										<div className="right-list">{symbol.isymbolcount}</div>
									)}
									{activeTab === "2" &&
										(filterType == "Stocks" ||
											filterType == "Indexes" ||
											filterType == "ETF") && (
											<KudoCons kudoConsCount={symbol.kudoscoins} />
										)}
									{activeTab === "2" && filterType == "Sectors" && (
										<KudoCons kudoConsCount={symbol.ckudoscoins} />
									)}
									{activeTab === "2" && filterType == "Industry" && (
										<KudoCons kudoConsCount={symbol.ikudoscoins} />
									)}
									{activeTab === "3" &&
										(filterType == "Stocks" ||
											filterType == "Indexes" ||
											filterType == "ETF") &&
										showSentiment(symbol.sentimentvalue, symbol.symbolcount)}
									{activeTab === "3" &&
										filterType == "Sectors" &&
										showSentiment(symbol.csentimentvalue, symbol.csymbolcount)}
									{activeTab === "3" &&
										filterType == "Industry" &&
										showSentiment(symbol.isentimentvalue, symbol.isymbolcount)}
								</ListGroupItem>
							</ListGroup>
						</div>
					)
				})
		}
		return null
	}

	const showSentiment = (value, count) => {
		const sentiment = value / count
		return (
			<div className="right-list">
				<Sentiment sentimentValue={sentiment.toFixed(2)} />
			</div>
		)
	}

	const displaySymbol = tabId => {
		switch (tabId) {
			case 1:
				{
					switch (filterType) {
						case "Stocks":
							return symbols(trendingSymbols)
							break
						case "Indexes":
							return symbols(trendingSymbols)
							break
						case "ETF":
							return symbols(trendingSymbols)
							break
						case "Sectors":
							return symbols(sectorTrending)
							break
						case "Industry":
							return symbols(industryTrending)
							break
						default:
							break
					}
				}
				break
			case 2:
				{
					switch (filterType) {
						case "Stocks":
							return symbols(kudosCoinsSymbols)
							break
						case "Indexes":
							return symbols(kudosCoinsSymbols)
							break
						case "ETF":
							return symbols(kudosCoinsSymbols)
							break
						case "Sectors":
							return symbols(sectorKudosCoins)
							break
						case "Industry":
							return symbols(industryKudosCoins)
							break
						default:
							break
					}
				}
				break
			case 3:
				{
					switch (filterType) {
						case "Stocks":
							const sortedSentiment =
								centimentSort == 1
									? sentimentSymbols != undefined
										? sentimentSymbols.sort((a, b) => {
												return (
													b.sentimentvalue / b.symbolcount -
													a.sentimentvalue / a.symbolcount
												)
										  })
										: []
									: sentimentSymbols != undefined
									? sentimentSymbols.sort((a, b) => {
											return (
												a.sentimentvalue / a.symbolcount -
												b.sentimentvalue / b.symbolcount
											)
									  })
									: []
							return symbols(sortedSentiment)
							break
						case "Indexes":
							const sortedSentimentIndexes =
								centimentSort == 1
									? sentimentSymbols != undefined
										? sentimentSymbols.sort((a, b) => {
												return (
													b.sentimentvalue / b.symbolcount -
													a.sentimentvalue / a.symbolcount
												)
										  })
										: []
									: sentimentSymbols != undefined
									? sentimentSymbols.sort((a, b) => {
											return (
												a.sentimentvalue / a.symbolcount -
												b.sentimentvalue / b.symbolcount
											)
									  })
									: []
							return symbols(sortedSentimentIndexes)
							break
						case "ETF":
							const sortedSentimentETF =
								centimentSort == 1
									? sentimentSymbols != undefined
										? sentimentSymbols.sort((a, b) => {
												return (
													b.sentimentvalue / b.symbolcount -
													a.sentimentvalue / a.symbolcount
												)
										  })
										: []
									: sentimentSymbols != undefined
									? sentimentSymbols.sort((a, b) => {
											return (
												a.sentimentvalue / a.symbolcount -
												b.sentimentvalue / b.symbolcount
											)
									  })
									: []
							return symbols(sortedSentimentETF)
							break
						case "Sectors":
							const sortedSentimentSectors =
								centimentSort == 1
									? sectorSentiment != undefined
										? sectorSentiment.sort((a, b) => {
												return (
													b.csentimentvalue / b.csymbolcount -
													a.csentimentvalue / a.csymbolcount
												)
										  })
										: []
									: sectorSentiment != undefined
									? sectorSentiment.sort((a, b) => {
											return (
												a.csentimentvalue / a.csymbolcount -
												b.csentimentvalue / b.csymbolcount
											)
									  })
									: []
							return symbols(sortedSentimentSectors)
							break
						case "Industry":
							const sortedSentimentIndustry =
								centimentSort == 1
									? industrySentiment != undefined
										? industrySentiment.sort((a, b) => {
												return (
													b.isentimentvalue / b.isymbolcount -
													a.isentimentvalue / a.isymbolcount
												)
										  })
										: []
									: industrySentiment != undefined
									? industrySentiment.sort((a, b) => {
											return (
												a.isentimentvalue / a.isymbolcount -
												b.isentimentvalue / b.isymbolcount
											)
									  })
									: []
							return symbols(sortedSentimentIndustry)
							break
						default:
							break
					}
				}
				break
			default:
				break
		}
	}

	useEffect(() => {
		localStorage.setItem("trendingSectorFilterType", filterType)
		loadFilterSwitch()
	}, [filterType])

	useEffect(() => {
		if (filterTime !== "1") {
			localStorage.setItem("trendingSectorFilterTime", filterTime)
			loadFilterSwitch()
		}
	}, [filterTime])

	useEffect(() => {
		localStorage.setItem("trendingSectorTechFund", techFund)
		loadFilterSwitch()
	}, [techFund])
	return (
		<>
			<div className="rs-box">
				<div className="d-flex justify-content-between">
					<h4 className="rs-title">Trending</h4>
					<div className="rs-title-filter d-flex align-items-center">
						<span
							role="button"
							onClick={() => {
								setFilterType("Stocks")
							}}>
							{filterType == "Stocks" ? <b>Stocks</b> : "Stocks"}
						</span>{" "}
						&nbsp;|&nbsp;
						<span
							role="button"
							onClick={() => {
								setFilterType("Indexes")
							}}>
							{filterType == "Indexes" ? <b>Indexes</b> : "Indexes"}
						</span>{" "}
						&nbsp;|&nbsp;
						<span
							role="button"
							onClick={() => {
								setFilterType("Sectors")
							}}>
							{filterType == "Sectors" ? <b>Sectors</b> : "Sectors"}
						</span>{" "}
						&nbsp;|&nbsp;
						<span
							role="button"
							onClick={() => {
								setFilterType("Industry")
							}}>
							{filterType == "Industry" ? <b>Industry</b> : "Industry"}
						</span>
						&nbsp;|&nbsp;
						<span
							role="button"
							onClick={() => {
								setFilterType("ETF")
							}}>
							{filterType == "ETF" ? <b>ETF</b> : "ETF"}
						</span>
					</div>
				</div>

				<div className="filter-list">
					<ListGroup>
						<Row className="mb-2">
							<Col>
								<FormGroup>
									<Input
										type="select"
										name="tecFundSelect"
										id="tecFundSelect"
										onChange={e => setTechFund(`${e.target.value}`)}
										value={techFund}>
										<option>All</option>
										<option>Technical</option>
										<option>Fundamental</option>
									</Input>
								</FormGroup>
							</Col>
							<Col>
								<FormGroup>
									<Input
										type="select"
										name="filterTime"
										id="filterTimeSelect"
										onChange={e => setFilterTime(`${e.target.value}`)}
										value={filterTime}>
										<option value="1">24hrs</option>
										<option value="7">7 days</option>
										<option value="30">30 days</option>
										<option value="365">1 year</option>
									</Input>
								</FormGroup>
							</Col>
						</Row>
						<Nav tabs>
							<NavItem style={{ fontSize: "11px" }}>
								<NavLink
									className={classnames({ active: activeTab === "1" })}
									onClick={() => {
										toggle("1")
									}}>
									Trending
								</NavLink>
							</NavItem>
							<NavItem style={{ fontSize: "11px" }}>
								<NavLink
									className={classnames({ active: activeTab === "2" })}
									onClick={() => {
										toggle("2")
									}}>
									Kudos Coins
								</NavLink>
							</NavItem>
							<NavItem style={{ fontSize: "11px" }}>
								<NavLink
									className={classnames({ active: activeTab === "3" })}
									onClick={() => {
										toggle("3")
										if (activeTab === "3") {
											setCentimentSort(centimentSort * -1)
										}
									}}>
									Sentiment &nbsp;
									{centimentSort == 1 && <i className="fas fa-sort-down"></i>}
									{centimentSort == -1 && <i className="fas fa-sort-up"></i>}
								</NavLink>
							</NavItem>
						</Nav>

						<TabContent activeTab={activeTab} className="mt-2">
							{loading && (
								<div className="d-flex justify-content-center">
									<div className="spinner-border" role="status">
										<span className="visually-hidden">Loading...</span>
									</div>
								</div>
							)}
							{!loading && (
								<>
									<TabPane tabId="1">{displaySymbol(1)}</TabPane>
									<TabPane tabId="2">{displaySymbol(2)}</TabPane>
									<TabPane tabId="3">{displaySymbol(3)}</TabPane>
								</>
							)}
						</TabContent>
					</ListGroup>
				</div>
			</div>
		</>
	)
}

export default withContext(
	([
		{
			app: { loadFilter },
		},
		dispatch,
	]) => ({
		loadFilterSwitch: () => loadFilterSwitchAction(dispatch),
	}),
	Ranking
)
