import React, { useEffect, useMemo, useState } from "react"
import { withContext } from "../../context/index"
import { Button, Modal, ModalHeader, ModalBody, Col, Row } from "reactstrap"
import axiosAuthHttpMiddelware from "common/axiosAuthHttpMiddelware"
import axiosHttpMiddelware from "common/axiosHttpMiddelware"
import RetweetModal from "./RetweetModal"
import "toastr/build/toastr.min.css"
import "../../assets/scss/custom/wethemkrt/people.scss"
import "../../assets/scss/custom/wethemkrt/link-preview.scss"
import "./PostListDashboard.scss"
import PostCreateModal from "./PostCreateModal"
import Skeleton from "react-loading-skeleton"
import "react-loading-skeleton/dist/skeleton.css"
import { postReloadSwitch as postReloadSwitchAction } from "../../store/actions/appActions"
import io from "socket.io-client"
import ImageCarousel from "./ImageCarousel"
import UserPost from "./UserPost"
import Toaster from "./Toaster"

//this component is responsible to fetch the data filterwise.
const PostListDashboard = ({ postReload, loadFilter, postReloadSwitch, ...props }) => {
	const [pageNo, setPageNo] = useState(0)
	const [who, setWho] = useState(false)
	const [category, setCategory] = useState(false)
	const [loader, setLoader] = useState(false)
	const [newPostCount, setNewPostCount] = useState(0)
	const [params, setParams] = useState(null)

	const [isPostModalOpen, setIsPostModalOpen] = useState(false)
	const [isRetweetModalOpen, setIsRetweetModalOpen] = useState(false)
	const [retweetData, setRetweetData] = useState(false)
	const [userPostData, setuserPostData] = useState([])
	const [post, setPost] = useState(null)
	const [isModalOpen, setIsModalOpen] = useState(false)
	const [modalImages, setModalImages] = useState([])
	const [modalImagesCurrentIndex, setModalImagesCurrentIndex] = useState(0)

	// create a reducer for the filter
	const [filterState, dispatch] = React.useReducer(
		(state, action) => {
			setuserPostData([])
			setNewPostCount(0)
			setPageNo(0)
			switch (action.type) {
				case "SET_FILTER_TAG":
					return { ...state, filterTag: action.payload }
				case "SET_FILTER_TIME":
					return { ...state, filterTime: action.payload }
				case "SET_FILTER_USER":
					return { ...state, filterUser: action.payload }
				default:
					return state
			}
		},
		{
			filterTag: 'All',
			filterTime: null,
			filterUser: null,
		}
	)
	
	const toggleModal = () => {
		setIsModalOpen(!isModalOpen)
	}
	const showModalImage = (images, index) => {
		setModalImages(JSON.parse(images))
		setModalImagesCurrentIndex(index)
		toggleModal()
	}
	const editPostModal = post => {
		setPost(post)
		setIsPostModalOpen(!isPostModalOpen)
	}
	const getNewPostCount = () => {
		// create a socket connection
		const socket = io(process.env.REACT_APP_SOCKET_URL)
		// listen for the event
		socket.on("post", data => {
			// increment the new post count
			setNewPostCount(newPostCount => newPostCount + 1)
		})
	}
	const getUserSavedPostList = async (page = null) => {
		setLoader(true)
		let authUser = {
			id: 0,
		}
		if (localStorage.getItem("user")) {
			authUser = JSON.parse(localStorage.getItem("user"))
		}
		axiosHttpMiddelware
			.get("/post/userDashboard", {
				params: {
					page: page !== null ? page : pageNo,
					userId: authUser.id,
					saved: 1,
				},
			})
			.then(response => {
				if (
					response.status === 200 &&
					response.data.postData !== undefined &&
					response.data.postData !== "" &&
					response.data.postData !== null
				) {
					if (response.data.postData.length) {
						if (userPostData.length == 0) {
							let postData = response.data.postData
							const postIds = postData.map(post => post.postguid)
							localStorage.setItem("userSavedPostIds", JSON.stringify(postIds))
						}
					}
				}
			})
			.catch(err => {
				console.log(err)
			})
	}
	useEffect(() => {
		if ((localStorage.getItem("trendingSectorFilterTime")||localStorage.getItem('trendingSectorTechFund')) && props.symbolname) {
			console.log(props.symbolname);
			if (localStorage.getItem("trendingSectorFilterTime")) {
				const trendingSectorFilterTime = localStorage.getItem(
					"trendingSectorFilterTime"
				)
				let time = null
				switch (parseInt(trendingSectorFilterTime)) {
					case 1: {
						time = "24 hour"
						break
					}
					case 7: {
						time = "7 day"
						break
					}
					case 30: {
						time = "30 day"
						break
					}
					case 365: {
						time = "1 year"
						break
					}
					default:
						break
				}
				dispatch({ type: "SET_FILTER_TIME", payload: time })
			}
			if(localStorage.getItem('trendingSectorTechFund')) {
				const trendingSectorTechFund = localStorage.getItem('trendingSectorTechFund')
				dispatch({ type: "SET_FILTER_TAG", payload: trendingSectorTechFund })
				setCategory(true)
			}
		} 
		setTimeout(() => {
			getUserSavedPostList()
		}, 100)
	}, [loadFilter])
	const getUserPostList = async () => {
		let symbol = props.symbolname ?? null
		setLoader(true)
		let authUser = {
			id: 0,
		}
		if (localStorage.getItem("user")) {
			authUser = JSON.parse(localStorage.getItem("user"))
		}
		const params = {
			symbol: symbol,
			time: filterState.filterTime,
			tag: filterState.filterTag,
			user: filterState.filterUser,
			what: null,
			page: pageNo,
			userId: authUser.id,
		};
		setParams(params)
	}

	useEffect(() => {
		if (params) {
			fetchPosts(params);
		}
	}, [params]);
	const fetchPosts = async params => {
		try {
			axiosHttpMiddelware
				.get("/post/userDashboard", {
					params: params,
				})
				.then(response => {
					if (
						response.status === 200 &&
						response.data.postData !== undefined &&
						response.data.postData !== "" &&
						response.data.postData !== null
					) {
						if (response.data.postData.length) {
							if (userPostData.length == 0) {
								let postData = response.data.postData
								const savedPostIds = localStorage.getItem("userSavedPostIds")
									? JSON.parse(localStorage.getItem("userSavedPostIds"))
									: []
								postData = postData.map(post => {
									const savedPost = savedPostIds.includes(post.postguid)
									if (savedPost) {
										post.bookmarkStatus = true
									} else {
										post.bookmarkStatus = false
									}
									return post
								})
								setuserPostData(postData)
							} else {
								setuserPostData(userPostData => [
									...userPostData,
									...response.data.postData,
								])
							}
							window.addEventListener("scroll", handleScroll)
							setTimeout(() => {
								setLoader(false)
							}, 1000)
						} else {
							setLoader(false)
						}
					}
				})
				.catch(err => {
					console.log(err)
				})
		} catch (err) {
			console.error(err)
		}
	}
	const handleScroll = () => {
		let userScrollHeight = window.innerHeight + window.scrollY + 1
		let windowBottomHeight = document.documentElement.offsetHeight
		if (userScrollHeight >= windowBottomHeight / 2) {
			window.removeEventListener("scroll", handleScroll, false)
			window.removeEventListener("scroll", handleScroll, true)
			setPageNo(pageNo + 1)
		}
	}
	useEffect(() => {
		setuserPostData([])
		setPageNo(0)
		setNewPostCount(0)
		getUserPostList()
		if (isPostModalOpen) {
			setIsPostModalOpen(!isPostModalOpen)
		}
	}, [postReload, props.symbolname])
	useEffect(() => {
		getUserPostList()
	}, [pageNo, filterState])
	useEffect(() => {
		window.addEventListener("scroll", handleScroll)
		if (localStorage.getItem("user")) {
			let user = JSON.parse(localStorage.getItem("user"))
			getUserInfo(user)
		}
		getNewPostCount()
	}, [])
	function getPostByTagFilter(e) {
		dispatch({ type: "SET_FILTER_TAG", payload: e.target.value })
	}
	const removeCategory = () => {
		dispatch({ type: "SET_FILTER_TAG", payload: null })
	}
	function getPostByUserFilter(e) {
		dispatch({ type: "SET_FILTER_USER", payload: e.target.value })
	}
	const removeWho = () => {
		dispatch({ type: "SET_FILTER_USER", payload: null })
	}
	function getPostbyTimeFilter(e) {
		dispatch({ type: "SET_FILTER_TIME", payload: e.target.value })
	}
	const getPostbyTimeFilterBlank = () => {
		dispatch({ type: "SET_FILTER_TIME", payload: null })
	}
	const getUserInfo = user => {
		axiosHttpMiddelware
			.post("/usergetbyid", { userid: user.id })
			.then(userResponse => {
				// setUserInfo(userResponse.data.userResponse)
			})
	}
	const updateRetweetCount = retweetData => {
		// find and update the userPostData ccount
		let userPostDataCopy = [...userPostData]
		userPostDataCopy.map(post => {
			if (post.postguid == retweetData.postguid) {
				post.ccount = parseInt(post.ccount) + 1
			}
			return post
		})
		setuserPostData(userPostDataCopy)
	}
	const postDelete = postguid => {
		// show a confirmation dialog
		const confirm = window.confirm("Are you sure you want to delete this post?")
		if (confirm) {
			// delete the post
			if (localStorage.getItem("user")) {
				axiosAuthHttpMiddelware
					.post("/post/delete", {
						postguid: postguid,
					})
					.then(response => {
						if (response.status == 200) {
							Toaster.successToaster("Post deleted successfully", "Success!")
							setuserPostData([])
							setPageNo(0)
							postReloadSwitch()
							setNewPostCount(0)
						}
					})
					.catch(err => {
						console.log(err)
					})
			}
		}
	}
	return (
		<React.Fragment>
			<div className="categorey-list bottom-border">
				<div className="category-filter-top">
					<div className="d-flex">
						<div className="m-2">
							<Button
								className={
									filterState.filterTime == null ? "btn btn-sm btn-danger" : "btn btn-sm"
								}
								onClick={() => {
									getPostbyTimeFilterBlank()
								}}>
								<i className="bx bxs-timer"></i>
								New
							</Button>
							&nbsp;
							<Button
								className={filterState.filterTime ? "btn btn-sm btn-danger" : "btn btn-sm"}
								onClick={() => {
									// getPostbyTimeFilterBlank()
									dispatch({ type: "SET_FILTER_TIME", payload: '24 hour' })
								}}>
								<i className="bx bxs-timer"></i>
								Top
							</Button>
							&nbsp;
							{filterState.filterTime && (
								<select
									className="form-select-sm filter-select"
									onChange={e => getPostbyTimeFilter(e)}
									value={filterState.filterTime}>
									<option value="24 hour">1 day</option>
									<option value="7 day">7 days</option>
									<option value="30 day">30 days</option>
									<option value="1 year">1 year</option>
								</select>
							)}
							&nbsp;
							<Button
								className={who ? "btn btn-sm btn-danger" : "btn btn-sm"}
								onClick={() => {
									if (who) {
										removeWho()
									}
									setWho(!who)
								}}>
								<i className="bx bx-user-plus"></i>
								Who
							</Button>
							&nbsp;
							{who && (
								<select
									className="form-select-sm filter-select"
									onChange={e => getPostByUserFilter(e)}>
									<option value="All">All</option>
									<option value="Following">Following</option>
									<option value="TopUsers">Top Users</option>
								</select>
							)}
							&nbsp;
							<Button
								className={category ? "btn btn-sm btn-danger" : "btn btn-sm"}
								onClick={() => {
									if (category) {
										removeCategory()
									}
									setCategory(!category)
								}}>
								<i className="bx bx-category"></i>
								Category
							</Button>
							&nbsp;
							{category && (
								<select
									className="form-select-sm filter-select"
									onChange={e => getPostByTagFilter(e)} value={filterState.filterTag}>
									<option value="All">All</option>
									<option value="Fundamental">Fundamental</option>
									<option value="Technical">Technical</option>
								</select>
							)}
						</div>
					</div>
					<div className="d-flex">
						<div className="filter-icon">
							<i className="bx bx-filter-alt "></i>
						</div>
						{newPostCount > 0 && (
							<div>
								<span className="badge bg-danger">{newPostCount} New post</span>
							</div>
						)}
						<span
							onClick={() => {
								setNewPostCount(0)
								setuserPostData([])
								setPageNo(0)
								getUserPostList()
							}}
							role="button"
							className="mx-3">
							<svg
								xmlns="http://www.w3.org/2000/svg"
								width="24"
								height="24"
								viewBox="0 0 24 24">
								<path d="M10 11H7.101l.001-.009a4.956 4.956 0 0 1 .752-1.787 5.054 5.054 0 0 1 2.2-1.811c.302-.128.617-.226.938-.291a5.078 5.078 0 0 1 2.018 0 4.978 4.978 0 0 1 2.525 1.361l1.416-1.412a7.036 7.036 0 0 0-2.224-1.501 6.921 6.921 0 0 0-1.315-.408 7.079 7.079 0 0 0-2.819 0 6.94 6.94 0 0 0-1.316.409 7.04 7.04 0 0 0-3.08 2.534 6.978 6.978 0 0 0-1.054 2.505c-.028.135-.043.273-.063.41H2l4 4 4-4zm4 2h2.899l-.001.008a4.976 4.976 0 0 1-2.103 3.138 4.943 4.943 0 0 1-1.787.752 5.073 5.073 0 0 1-2.017 0 4.956 4.956 0 0 1-1.787-.752 5.072 5.072 0 0 1-.74-.61L7.05 16.95a7.032 7.032 0 0 0 2.225 1.5c.424.18.867.317 1.315.408a7.07 7.07 0 0 0 2.818 0 7.031 7.031 0 0 0 4.395-2.945 6.974 6.974 0 0 0 1.053-2.503c.027-.135.043-.273.063-.41H22l-4-4-4 4z"></path>
							</svg>
						</span>
					</div>
				</div>
			</div>
			{userPostData.length > 0
				? userPostData.map((element, key) => (
						<UserPost
							element={element}
							key={key}
							showModalImage={showModalImage}
							editPostModal={editPostModal}
							postDelete={postDelete}
						/>
				  ))
				: null}
			{loader && (
				<div className="ct-postbox">
					<div className="userpost-box">
						<div className="usbox-profile">
							<Skeleton circle={true} height={50} width={50} />
						</div>
						<div className="usbox-right-content">
							<div className="ubox-top">
								<div>
									<div>
										<h5 className="m-0">
											<Skeleton width={200} />
										</h5>
										<h6>
											<Skeleton width={300} />
										</h6>
									</div>
								</div>
							</div>
						</div>
					</div>
					<div className="userpost-box">
						<div className="arrow-box">
							<div className="short-arw-box topar-box">
								<Skeleton width={30} height={30} />
							</div>
							<div className="short-arw-box">
								<Skeleton width={30} height={30} />
							</div>
							<div className="short-arw-box downar-box">
								<Skeleton width={30} height={30} />
							</div>
						</div>
						<div className="usbox-right-content">
							<div className="mb-0 message-img pt-3">
								<Skeleton width={500} height={300} />
							</div>
						</div>
					</div>
					<hr />
				</div>
			)}
			<Modal
				className="modal-fullscreen"
				style={{ width: "auto" }}
				fullscreen={true}
				isOpen={isModalOpen}
				toggle={toggleModal}>
				<ModalHeader toggle={toggleModal}></ModalHeader>
				<ModalBody>
					<Row>
						<Col md="12">
							<ImageCarousel
								images={modalImages}
								currentIndex={modalImagesCurrentIndex}
							/>
						</Col>
					</Row>
				</ModalBody>
			</Modal>
			<RetweetModal
				open={isRetweetModalOpen}
				toggle={() => {
					setIsRetweetModalOpen(!isRetweetModalOpen)
				}}
				retweetData={retweetData}
				updateRetweetCount={updateRetweetCount}
			/>
			<PostCreateModal
				open={isPostModalOpen}
				toggle={() => {
					setIsPostModalOpen(!isPostModalOpen)
				}}
				post={post}
			/>
		</React.Fragment>
	)
}
export default withContext(
	([
		{
			app: { postReload, loadFilter },
		},
		dispatch,
	]) => ({
		postReload: postReload,
		loadFilter: loadFilter,
		postReloadSwitch: () => postReloadSwitchAction(dispatch),
	}),
	PostListDashboard
)
