import React, { useEffect, useState } from "react"
import { withRouter } from "react-router-dom"
import PropTypes from "prop-types"
import Header from "./Header"
import { Container, Row, Col, Card } from "reactstrap"
import SideBar from "components/CommonLanding/SideBar"
import TrendingSymbol from "components/Symbol/TrendingSymbol"
import UserKudos from "components/Users/UserKudos"
import Ranking from "../Ranking"
import "./layout.scss"
import TawkMessengerReact from '@tawk.to/tawk-messenger-react';
const Layout = props => {
	const [isMobile, setIsMobile] = useState(false)
	const [isMenuOpen, setIsMenuOpen] = useState(false)
	useEffect(() => {
		// Check if the screen width is less than a certain breakpoint (e.g., 768px) to determine if it's a mobile device
		const isMobileDevice = window.innerWidth-300 < 768

		setIsMobile(isMobileDevice)

		// Add a resize event listener to update the isMobile state when the window size changes
		const handleResize = () => {
			setIsMobile(window.innerWidth < 768)
		}

		window.addEventListener("resize", handleResize)

		// Clean up the event listener on component unmount
		return () => {
			window.removeEventListener("resize", handleResize)
		}
	}, [])
	const toggleMenu = () => {
		setIsMenuOpen(!isMenuOpen)
	}
	useEffect(() => {
		const title = props.location.pathname
		let currentage = title.charAt(1).toUpperCase() + title.slice(2)
		document.title = currentage + " "
	}, [props.location.pathname])
	useEffect(() => {
		window.scrollTo(0, 0)
	}, [])
	const [isMenuOpened, setIsMenuOpened] = useState(false)
	const openMenu = () => {
		setIsMenuOpened(!isMenuOpened)
	}
	return (
		<React.Fragment>
			<div className="dashboard-wrapper">
				<div class="theme-container">
					<Header isMobile={isMobile} isMenuOpen={isMenuOpen} toggleMenu={toggleMenu} />
					<div class="dashboard-containbox">
						<div className="left_sidebar">
							<SideBar
							isMobile={isMobile}
							isMenuOpen={isMenuOpen}
							toggleMenu={toggleMenu}
							></SideBar>
						</div>
						<div className="mainbody-wrapper">
							<div
								className={`${!props.hideRightCards ? "inner-bodypart" : ""}`}>
								<div className="inner-body-content">
									{props.children}
								</div>
							</div>
							{!props.hideRightCards && (
								<div className="right-sidebar">
									<Ranking />
									<UserKudos props={props} />
									{/* <TrendingSymbol /> */}
								</div>
							)}
						</div>
					</div>
				</div>
			</div>
			<TawkMessengerReact
                propertyId="66be937d146b7af4a43af5ae"
                widgetId="1i5c7096l"/>
		</React.Fragment>
	)
}
Layout.propTypes = {
	changeLayout: PropTypes.func /*  */,
	changeLayoutWidth: PropTypes.func,
	changeTopbarTheme: PropTypes.func,
	children: PropTypes.object,
	isPreloader: PropTypes.any,
	layoutWidth: PropTypes.any,
	location: PropTypes.object,
	topbarTheme: PropTypes.any,
}
export default withRouter(Layout)
