import React from "react"
import HorizontalLayout from "../../components/HorizontalLayout"
import axiosAuthHttpMiddelware from "../../common/axiosAuthHttpMiddelware"
import axiosHttpMiddelware from "../../common/axiosHttpMiddelware"
import toaster from "../../components/Common/Toaster"
import {
	Card,
	CardBody,
	CardHeader,
	Button,
	Collapse,
	ListGroup,
	ListGroupItem,
	FormGroup,
	Input,
} from "reactstrap"
import Skeleton from "react-loading-skeleton"
import "./Trending.scss"
import { useHistory } from "react-router-dom"
import { withContext } from "context"
import Sentiment from "components/Common/Sentiment"
import KudoCons from "components/Common/KudoCons"

const Trending = ({ loadFilter }) => {
	const history = useHistory()
	// copy from client/src/components/Ranking/index.js

	const [industryTrending, setIndustryTrending] = React.useState()
	const [sectorTrending, setSectorTrending] = React.useState()
	const [loading, setLoading] = React.useState(false)
	const [showIndustry, setShowIndustry] = React.useState(false)
	const [showSector, setShowSector] = React.useState(false)
	const [selectedIndustry, setSelectedIndustry] = React.useState([])
	const [selectedSector, setSelectedSector] = React.useState([])
	const [symbols, setSymbols] = React.useState([])
	const [filteredIndustrySymbols, setFilteredIndustrySymbols] = React.useState(
		[]
	)
	const [filteredSectorSymbols, setFilteredSectorSymbols] = React.useState([])
	const [filterTime, setFilterTime] = React.useState("365")
	const [industryTrendingSorting, setIndustryTrendingSorting] =
		React.useState("")
	const [sectorTrendingSorting, setSectorTrendingSorting] = React.useState("")
	const [sortDirectionIndustry, setSortDirectionIndustry] =
		React.useState("desc")
	const [sortDirectionSector, setSortDirectionSector] = React.useState("desc")
	const [showTrendingIndustry, setShowTrendingIndustry] = React.useState(false)
	const [showTrendingSector, setShowTrendingSector] = React.useState(false)

	React.useEffect(() => {
		getTrendingSymbol()
		localStorage.setItem("trendingSectorFilterTime", filterTime)
	}, [filterTime])

	const getTrendingSymbol = async () => {
		setLoading(true)
		axiosAuthHttpMiddelware
			.get("/getSymbolList", {
				params: {
					tag: "All",
					time: filterTime,
				},
			})
			.then(response => {
				if (response.status == 200) {
					setSymbols(response.data.symbolResponse)
					setIndustryTrending(response.data.industryTrendingResponse)
					if (localStorage.getItem("trendingIndustry")) {
						setSelectedIndustry([localStorage.getItem("trendingIndustry")])
						setShowTrendingIndustry(true)
					} else {
						setSelectedIndustry(
							response.data.industryTrendingResponse
								.slice(0, 5)
								.map(item => item.industry)
						)
						setShowTrendingIndustry(false)
					}
					setSectorTrending(response.data.sectorTrendingResponse)
					if (localStorage.getItem("trendingSector")) {
						setSelectedSector([localStorage.getItem("trendingSector")])
						setShowTrendingSector(true)
					} else {
						setSelectedSector(
							response.data.sectorTrendingResponse
								.slice(0, 5)
								.map(item => item.sector)
						)
						setShowTrendingSector(false)
					}
					setLoading(false)
				} else {
					setLoading(false)
				}
			})
			.catch(err => {
				console.log(err)
				setLoading(false)
			})
	}

	React.useEffect(() => {
		setTimeout(() => {
			if (localStorage.getItem("trendingSectorFilterTime")) {
				setFilterTime(localStorage.getItem("trendingSectorFilterTime"))
			}
		}, 200)
	}, [loadFilter])

	React.useEffect(() => {
		setTimeout(() => {
			getTrendingSymbol()
		}, 200)
	}, [loadFilter])

	React.useEffect(() => {
		// filter symbols based on selected industry
		if (selectedIndustry.length > 0) {
			const filteredSymbols = symbols.filter(item =>
				selectedIndustry.includes(item.industry)
			)
			setFilteredIndustrySymbols(filteredSymbols)
		} else {
			setFilteredIndustrySymbols(symbols)
		}
	}, [selectedIndustry])

	React.useEffect(() => {
		// filter symbols based on selected sector
		if (selectedSector.length > 0) {
			const filteredSymbols = symbols.filter(item =>
				selectedSector.includes(item.sector)
			)
			setFilteredSectorSymbols(filteredSymbols)
		} else {
			setFilteredSectorSymbols(symbols)
		}
	}, [selectedSector])

	const showSymbolSentiment = (sentiment, count) => {
		const sentimentValue = sentiment / count
		const sentimentValueFixed = sentimentValue.toFixed(2)
		return <Sentiment sentimentValue={sentimentValueFixed} />
	}

	const sortIndustrySymbols = key => {
		let sortDirectionIndustryI = "desc"
		if (key == industryTrendingSorting) {
			sortDirectionIndustryI = sortDirectionIndustry === "asc" ? "desc" : "asc"
			setSortDirectionIndustry(sortDirectionIndustryI)
		} else {
			setSortDirectionIndustry("desc")
		}
		const sortedSymbols = filteredIndustrySymbols.sort((a, b) => {
			const aValue = key === "sentimentvalue" ? a[key] / a.symbolcount : a[key]
			const bValue = key === "sentimentvalue" ? b[key] / b.symbolcount : b[key]
			if (sortDirectionIndustryI === "desc") {
				if (aValue > bValue) {
					return -1
				}
				if (aValue < bValue) {
					return 1
				}
			} else {
				if (aValue < bValue) {
					return -1
				}
				if (aValue > bValue) {
					return 1
				}
			}
			return 0
		})
		setFilteredIndustrySymbols([...sortedSymbols])
		setIndustryTrendingSorting(key)
	}

	const sortSectorSymbols = key => {
		let sortDirectionSectorI = "desc"
		if (key == sectorTrendingSorting) {
			sortDirectionSectorI = sortDirectionSector === "asc" ? "desc" : "asc"
			setSortDirectionSector(sortDirectionSectorI)
		} else {
			setSortDirectionSector("desc")
		}
		const sortedSymbols = filteredSectorSymbols.sort((a, b) => {
			const aValue = key === "sentimentvalue" ? a[key] / a.symbolcount : a[key]
			const bValue = key === "sentimentvalue" ? b[key] / b.symbolcount : b[key]
			if (sortDirectionSectorI === "desc") {
				if (aValue > bValue) {
					return -1
				}
				if (aValue < bValue) {
					return 1
				}
			} else {
				if (aValue < bValue) {
					return -1
				}
				if (aValue > bValue) {
					return 1
				}
			}
			return 0
		})
		setFilteredSectorSymbols([...sortedSymbols])
		setSectorTrendingSorting(key)
	}

	const symboal = params => {
		history.push(`/feed/${params}`)
	}

	const toggleIndustryCollapse = () => {
		setShowTrendingIndustry(!showTrendingIndustry)
	}

	const toggleSectorCollapse = () => {
		setShowTrendingSector(!showTrendingSector)
	}

	return (
		<React.Fragment>
			<HorizontalLayout>
				{!loading ? (
					<div className="trending">
						<h1 className="rs-title">Trending</h1>
						<Card className="white-boxpart rs-box">
							<CardHeader
								className="d-flex justify-content-between align-items-center cursor-pointer"
								onClick={toggleIndustryCollapse}>
								<h4 className="rs-title">Trending Stocks by Industry</h4>
								<i
									className={`bi ${
										showTrendingIndustry
											? "fa fa-chevron-down"
											: "fa fa-chevron-up"
									}`}></i>
							</CardHeader>
							<Collapse
								isOpen={
									showTrendingIndustry
								}>
								<CardBody>
									<div className="search-filter">
										<div className="sf-row">
											<div className="sf-group sf-6">
												<div
													onClick={() => {
														setShowIndustry(!showIndustry)
													}}
													role="button">
													{showIndustry ? (
														<i className="fa fa-chevron-up"></i>
													) : (
														<i className="fa fa-chevron-down"></i>
													)}
													Trending Industries
												</div>
												{showIndustry && (
													<div className="dropdown">
														<ul className="dropdown-list">
															{industryTrending &&
																industryTrending.length > 0 &&
																industryTrending
																	.slice(0, 10)
																	.map((item, index) => (
																		<li key={index}>
																			<input
																				type="checkbox"
																				id={item.industry}
																				name={item.industry}
																				value={item.industry}
																				className="dropdown-checkbox"
																				checked={selectedIndustry.includes(
																					item.industry
																				)}
																				onChange={e => {
																					if (e.target.checked) {
																						setSelectedIndustry([
																							...selectedIndustry,
																							e.target.value,
																						])
																					} else {
																						setSelectedIndustry(
																							selectedIndustry.filter(
																								item => item !== e.target.value
																							)
																						)
																					}
																					localStorage.removeItem(
																						"trendingIndustry"
																					)
																				}}
																			/>
																			<label htmlFor={item.industry}>
																				{item.industry}
																			</label>
																		</li>
																	))}
														</ul>
													</div>
												)}
											</div>
											<div className="sf-group sf-6">
												<FormGroup>
													<Input
														type="select"
														name="filterTime"
														id="filterTimeSelect"
														onChange={e => setFilterTime(`${e.target.value}`)}
														value={filterTime}>
														<option value="1">24hrs</option>
														<option value="7">7 days</option>
														<option value="30">30 days</option>
														<option value="365">1 year</option>
													</Input>
												</FormGroup>
											</div>
										</div>
										<div className="filter-list main-filterlist">
											<ListGroup>
												<ListGroupItem className="fl-list-group top-flg">
													<div className="table-list sf-4">
														<b>Stocks</b>
													</div>
													<div
														className="table-list sf-4"
														onClick={() => sortIndustrySymbols("symbolcount")}
														role="button">
														<b>Post Count</b>&nbsp;
														{industryTrendingSorting === "symbolcount" ? (
															sortDirectionIndustry == "asc" ? (
																<i className="fa fa-sort-down" />
															) : (
																<i className="fa fa-sort-up" />
															)
														) : (
															<i className="fa fa-sort" />
														)}
													</div>
													<div
														className="table-list sf-4"
														onClick={() => sortIndustrySymbols("kudoscoins")}
														role="button">
														<b>Kudos coins</b>&nbsp;
														{industryTrendingSorting === "kudoscoins" ? (
															sortDirectionIndustry == "asc" ? (
																<i className="fa fa-sort-down" />
															) : (
																<i className="fa fa-sort-up" />
															)
														) : (
															<i className="fa fa-sort" />
														)}
													</div>
													<div
														className="table-list sf-4"
														onClick={() =>
															sortIndustrySymbols("sentimentvalue")
														}
														role="button">
														<b>Sentiment</b>&nbsp;
														{industryTrendingSorting === "sentimentvalue" ? (
															sortDirectionIndustry == "asc" ? (
																<i className="fa fa-sort-down" />
															) : (
																<i className="fa fa-sort-up" />
															)
														) : (
															<i className="fa fa-sort" />
														)}
													</div>
												</ListGroupItem>
												{filteredIndustrySymbols.length == 0 ? (
													<Skeleton count={5} />
												) : (
													filteredIndustrySymbols.map((item, index) => (
														<ListGroupItem
															key={index}
															className="fl-list-group">
															<div
																className="table-list sf-4"
																role="button"
																onClick={() => {
																	symboal(item.symbolname.replace("$", ""))
																}}>
																{item.symbolname}
															</div>
															<div className="table-list sf-4">
																{item.symbolcount}
															</div>
															<div className="table-list sf-4">
																<KudoCons kudoConsCount={item.kudoscoins} />
															</div>
															<div className="table-list sf-4">
																{showSymbolSentiment(
																	item.sentimentvalue,
																	item.symbolcountwithcentiment
																)}
															</div>
														</ListGroupItem>
													))
												)}
											</ListGroup>
										</div>
									</div>
								</CardBody>
							</Collapse>
						</Card>

						<Card className="white-boxpart rs-box">
							<CardHeader
								className="d-flex justify-content-between align-items-center cursor-pointer"
								onClick={toggleSectorCollapse}>
								<h4 className="rs-title">Trending Stocks by Sector</h4>
								<i
									className={`bi ${
										showTrendingSector
											? "fa fa-chevron-down"
											: "fa fa-chevron-up"
									}`}></i>
							</CardHeader>
							<Collapse
								isOpen={
									showTrendingSector
								}>
								<CardBody>
									<div className="search-filter">
										<div className="sf-row">
											<div className="sf-group sf-6">
												<div
													onClick={() => {
														setShowSector(!showSector)
													}}
													role="button">
													{showSector ? (
														<i className="fa fa-chevron-up"></i>
													) : (
														<i className="fa fa-chevron-down"></i>
													)}
													Trending Sectors
												</div>
												{showSector && (
													<div className="dropdown">
														<ul className="dropdown-list">
															{sectorTrending &&
																sectorTrending.length > 0 &&
																sectorTrending
																	.slice(0, 10)
																	.map((item, index) => (
																		<li key={index}>
																			<input
																				type="checkbox"
																				id={item.sector}
																				name={item.sector}
																				value={item.sector}
																				className="dropdown-checkbox"
																				checked={selectedSector.includes(
																					item.sector
																				)}
																				onChange={e => {
																					if (e.target.checked) {
																						setSelectedSector([
																							...selectedSector,
																							e.target.value,
																						])
																					} else {
																						setSelectedSector(
																							selectedSector.filter(
																								item => item !== e.target.value
																							)
																						)
																					}
																					localStorage.removeItem(
																						"trendingSector"
																					)
																				}}
																			/>
																			<label htmlFor={item.sector}>
																				{item.sector}
																			</label>
																		</li>
																	))}
														</ul>
													</div>
												)}
											</div>
											<div className="sf-group sf-6">
												<FormGroup>
													<Input
														type="select"
														name="filterTime"
														id="filterTimeSelect"
														onChange={e => setFilterTime(`${e.target.value}`)}
														value={filterTime}>
														<option value="1">24hrs</option>
														<option value="7">7 days</option>
														<option value="30">30 days</option>
														<option value="365">1 year</option>
													</Input>
												</FormGroup>
											</div>
										</div>
										<div className="filter-list main-filterlist">
											<ListGroup>
												<ListGroupItem className="fl-list-group top-flg">
													<div className="table-list sf-4">
														<b>Stocks</b>
													</div>
													<div
														className="table-list sf-4"
														onClick={() => sortSectorSymbols("symbolcount")}
														role="button">
														<b>Post Count</b>&nbsp;
														{sectorTrendingSorting === "symbolcount" ? (
															sortDirectionSector == "asc" ? (
																<i className="fa fa-sort-down" />
															) : (
																<i className="fa fa-sort-up" />
															)
														) : (
															<i className="fa fa-sort" />
														)}
													</div>
													<div
														className="table-list sf-4"
														onClick={() => sortSectorSymbols("kudoscoins")}
														role="button">
														<b>Kudos coins</b>&nbsp;
														{sectorTrendingSorting === "kudoscoins" ? (
															sortDirectionSector == "asc" ? (
																<i className="fa fa-sort-down" />
															) : (
																<i className="fa fa-sort-up" />
															)
														) : (
															<i className="fa fa-sort" />
														)}
													</div>
													<div
														className="table-list sf-4"
														onClick={() => sortSectorSymbols("sentimentvalue")}
														role="button">
														<b>Sentiment</b>&nbsp;
														{sectorTrendingSorting === "sentimentvalue" ? (
															sortDirectionSector == "asc" ? (
																<i className="fa fa-sort-down" />
															) : (
																<i className="fa fa-sort-up" />
															)
														) : (
															<i className="fa fa-sort" />
														)}
													</div>
												</ListGroupItem>
												{filteredSectorSymbols.length == 0 ? (
													<Skeleton count={5} />
												) : (
													filteredSectorSymbols.map((item, index) => (
														<ListGroupItem
															key={index}
															className="fl-list-group">
															<div
																className="table-list sf-4"
																role="button"
																onClick={() => {
																	symboal(item.symbolname.replace("$", ""))
																}}>
																{item.symbolname}
															</div>
															<div className="table-list sf-4">
																{item.symbolcount}
															</div>
															<div className="table-list sf-4">
																<KudoCons kudoConsCount={item.kudoscoins} />
															</div>
															<div className="table-list sf-4">
																{showSymbolSentiment(
																	item.sentimentvalue,
																	item.symbolcount
																)}
															</div>
														</ListGroupItem>
													))
												)}
											</ListGroup>
										</div>
									</div>
								</CardBody>
							</Collapse>
						</Card>
					</div>
				) : (
					<Skeleton count={5} />
				)}
			</HorizontalLayout>
		</React.Fragment>
	)
}

export default withContext(
	([
		{
			app: { loadFilter },
		},
		dispatch,
	]) => ({
		loadFilter: loadFilter,
	}),
	Trending
)
