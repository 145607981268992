import PropTypes from "prop-types"
import React, { useState, useEffect } from "react"
import { Progress } from "reactstrap"
import { withRouter } from "react-router-dom"
import moment from "moment"
import { useQuery } from "react-query"
import { useHistory } from "react-router-dom"
import axiosHttpMiddelware from "common/axiosHttpMiddelware"
import axiosAuthHttpMiddelware from "common/axiosAuthHttpMiddelware"
import Toaster from "../../Common/Toaster"
import "./ViewUserProfile.scss"
import FollowBtn from "./FollowBtn"



// API call to fetch Kudos Coin info
const fetchKudosCoin = async userId => {
	const { data } = await axiosHttpMiddelware.post("getKudosCountByUser", {
		userid: userId,
	})
	return data.kudosCoinResponse
}

const ViewUserProfile = props => {
	const [username, setUsername] = useState(null)
	const [authUser, setAuthUser] = useState(null)
	const fileInputRef = React.useRef()
	const searchuserInfo = props?.userInfo;
	useEffect(() => {
		if (
			props.match.params !== undefined &&
			props.match.params.username !== undefined
		) {
			setUsername(props.match.params.username)
		}
		if (localStorage.getItem("user")) {
			const obj = JSON.parse(localStorage.getItem("user"))
			setAuthUser(obj)
			if (obj.username == props.match.params.username) {
				setisEditProfile(true)
			} else {
				setisEditProfile(false)
			}
		}
	}, [props.match.params.username])
	const history = useHistory()
	const [isEditProfile, setisEditProfile] = useState(false)
	const { data: kudosCoin, isError: kudosError } = useQuery(
		["kudosCoin", searchuserInfo?.id],
		() => fetchKudosCoin(searchuserInfo?.id),
		{ enabled: !!searchuserInfo?.id }
	)

	useEffect(() => {
		if (kudosError) {
			Toaster.errorToaster("Error fetching kudoscoin.", "Error")
		}
	}, [kudosError])

	function editProfile(params) {
		history.push(`/profile/${params}`)
	}

	// function to upload image to s3 bucket
	const uploadToStorage = async file => {
		// Set your Linode Object Storage credentials
		const linodeConfig = {
			accessKeyId: process.env.REACT_APP_FILE_STORAGE_ACCESS_KEY,
			secretAccessKey: process.env.REACT_APP_FILE_STORAGE_SECRET_KEY,
			region: process.env.REACT_APP_FILE_STORAGE_REGION,
			endpoint: process.env.REACT_APP_FILE_STORAGE_ENDPOINT,
		}

		// Create an S3 client
		const s3 = new AWS.S3(linodeConfig)

		// Specify the bucket name and the key (path) where you want to upload the image
		const bucketName = process.env.REACT_APP_FILE_STORAGE_BUCKET_NAME

		// get the file extension
		const fileExtension = file.name.split(".").pop()
		// create a unique file name for the image
		const key = `${
			process.env.REACT_APP_OTHER_IMAGE_FOLDER
		}/${Date.now().toString()}.${fileExtension}`

		// Read the file content
		const fileContent = await file.arrayBuffer()

		// Set the parameters for the S3 upload
		const params = {
			Bucket: bucketName,
			Key: key,
			Body: Buffer.from(fileContent),
		}

		// set the access control headers
		params.ACL = "public-read"

		try {
			// Upload the image to Linode Object Storage
			const data = await s3.upload(params).promise()
			return data.Location
		} catch (error) {
			console.error("Error uploading image:", error)
			return false
		}
	}

	function updateCoverPhoto(file) {
		if (localStorage.getItem("user")) {
			axiosAuthHttpMiddelware
				.post("/updateCoverPhoto", file)
				.then(response => {
					Toaster.successToaster(response.data.message, "Success!")
					getUsers()
				})
				.catch(err => {
					console.log(err)
					Toaster.errorToaster("Could not update cover photo", "Error")
				})
		}
	}

	function showOpenFile() {
		fileInputRef.current.click()
	}

	return (
		<React.Fragment>
			<div class="white-boxpart rs-box">
				<div class="profile-wrapper view-profilebox">
					<div class="pw-banner">
						<img
							src={
								searchuserInfo?.coverphoto ||
								"https://s.yimg.com/ny/api/res/1.2/vEVSkt.K2ATKMnogEB.eWg--/YXBwaWQ9aGlnaGxhbmRlcjt3PTEyMDA7aD03OTI7Y2Y9d2VicA--/https://media.zenfs.com/en/ap_finance_articles_694/2b9a03a53108b83c3dadf1870990e240"
							}
							alt="Cover"
						/>
						{isEditProfile && (
							<div class="edit1-btn">
								<input
									ref={fileInputRef}
									type="file"
									onChange={e => {
										// upload the file to s3 bucket
										uploadToStorage(e.target.files[0]).then(url => {
											// update the cover photo
											updateCoverPhoto({
												coverphoto: url,
												userId: authUser.id,
											})
										})
									}}
								/>
								<a href="javascript:void(0)" onClick={showOpenFile}>
									<i class="bx bxs-edit"></i>
								</a>
							</div>
						)}
					</div>
					<div class="pw-userinfo">
						<div class="pw-user-profile">
							<img src={searchuserInfo?.profilephoto ||
									"https://s42013.pcdn.co/wp-content/uploads/2016/07/Kenny-Lange-PHNX21Design-Client-Report.jpg"} alt="Profile" />
							{isEditProfile && (
								<div class="edit1-btn">
									<input
										ref={fileInputRef}
										type="file"
										onChange={e => {
											// upload the file to s3 bucket
											uploadToStorage(e.target.files[0]).then(url => {
												// update the cover photo
												updateCoverPhoto({
													coverphoto: url,
													userId: authUser.id,
												})
											})
										}}
									/>
									<a href="javascript:void(0)" onClick={showOpenFile}>
										<i class="bx bxs-edit"></i>
									</a>
								</div>
							)}
						</div>
						<div class="pw-extrainfo">
							<div class="lft-side">
								<Progress
									className="top-progress"
									color="primary"
									value={100 - kudosCoin?.progress}
								/>
								{kudosCoin?.progress < 50 ? (
									<p>Top {kudosCoin?.progress}% of users</p>
								) : (
									<p>
										Progress {100 - kudosCoin?.progress}% based on <br />
										kudos coins
									</p>
								)}
							</div>
							<div class="rt-side">
								<span class="coin-icon">
									<i class="bx bx-dollar-circle dollar-icon-uesr"></i>
									{kudosCoin?.kudoscoin}
								</span>
								<h6>Kudos Coins</h6>
							</div>
						</div>
					</div>
					<div class="pw-user-info">
						<div class="user-content">
							<h5>{searchuserInfo?.username}</h5>
							<h6>@{username}</h6>
						</div>
						<p>{searchuserInfo?.about}</p>
						<div class="user-author">
							{searchuserInfo?.location && (
								<div class="author-grid">
									<div class="gridicon">
										<i class="bx bx-map"></i>
									</div>
									<div class="ag-name">{searchuserInfo?.location}</div>
								</div>
							)}
							<div class="author-grid">
								<div class="gridicon">
									<i class="bx bx-calendar"></i>
								</div>
								<div class="ag-name">
									Joined {moment(searchuserInfo?.createdAt).format("LL")}
								</div>
							</div>
						</div>
						<div class="follow-list">
							<ul>
								<li>
									<label>{searchuserInfo?.following?.length}</label> Following
								</li>
								<li>
									<label>{searchuserInfo?.follower?.length}</label> Followers
								</li>
							</ul>
						</div>
						<div class="profile_follow_button">
							{isEditProfile ? (
								<a
									href="javascript:void(0)"
									onClick={e => editProfile(searchuserInfo?.name)}
									className="edit-btn">
									{" "}
									<i class="bx bxs-edit"></i>Edit
								</a>
							) : (
								<FollowBtn searchUserInfo={props.userInfo} />
							)}
						</div>
					</div>
				</div>
			</div>
		</React.Fragment>
	)
}
ViewUserProfile.propTypes = {
	editProfile: PropTypes.func,
	error: PropTypes.any,
	success: PropTypes.any,
	userInfo: PropTypes.object,
}
export default withRouter(ViewUserProfile)
