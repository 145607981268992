import React, { useEffect, useState } from "react"
import { Row, Col, Card } from "reactstrap"
import HorizontalLayout from "components/HorizontalLayout"
import ViewUserProfile from "./ViewUserProfile"
import UserPostCard from "./UserPostCard"
import UserFollowCard from "./UserFollowCard"
import axiosHttpMiddelware from "common/axiosHttpMiddelware"
import Skeleton from "react-loading-skeleton"
import "react-loading-skeleton/dist/skeleton.css"
import { useQuery } from "react-query"
import Toaster from "../../Common/Toaster"

// API call to get user details by username
const fetchUserDetails = async username => {
	const { data } = await axiosHttpMiddelware.post("/usergetbyusername", {
		username,
	})
	return data.userResponse
}

// API call to fetch user posts
const fetchUserPosts = async userId => {
	const { data } = await axiosHttpMiddelware.get("/post/userDashboard", {
		params: { userId: userId, onlyUser: true },
	})
	return data.postData
}
const UserProfileIndex = props => {
	const [username, setUsername] = useState(null)
	// Fetch user details using react-query
	const {
		data: searchuserInfo,
		isLoading: isLoadingUser,
		isError: userError,
	} = useQuery(["user", username], () => fetchUserDetails(username), {
		enabled: !!username,
		onError: () => Toaster.errorToaster("Could not fetch user info", "Error"),
	})

	// Fetch user posts using react-query
	const {
		data: userPostData,
		isLoading: isLoadingPosts,
		isError: postsError,
	} = useQuery(
		["posts", searchuserInfo?.id],
		() => fetchUserPosts(searchuserInfo?.id),
		{
			enabled: !!searchuserInfo?.id,
		}
	)

	useEffect(() => {
		setUsername(props.match.params.username)
	}, [props.match.params.username])
	return (
		<React.Fragment>
			<HorizontalLayout>
				<div className="dashboard-body">
					{searchuserInfo && (
						<ViewUserProfile
							userPostData={userPostData}
							dataParentToChild={props}
							userInfo={searchuserInfo}
						/>
					)}
					{searchuserInfo && (
						<UserFollowCard data={searchuserInfo} dataParentToChild={props} />
					)}
					{searchuserInfo?.username && (
						<UserPostCard userInfo={searchuserInfo} dataParentToChild={props} />
					)}
				</div>
			</HorizontalLayout>
		</React.Fragment>
	)
}
export default UserProfileIndex
