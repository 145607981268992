import React, { useState } from "react"
import { useHistory } from "react-router-dom"
import "./BeteLanding.scss"
import sideBannerImage from "../../assets/images/home-banner.png"
import logo from "../../assets/images/logo-new.png"
import Login from "../../components/CommonForBoth/AuthenticationModel/Login"
import ChangePassword from "../../components/CommonForBoth/AuthenticationModel/ChangePassword"
import Signup from "../../components/CommonForBoth/AuthenticationModel/Signup"
import tick from "../../assets/images/tick.png"

const BetaLanding = () => {

	const history = useHistory()
	const [modal_backdroplogin, setmodal_backdroplogin] = useState(false)
	const [modal_backdropsignup, setmodal_backdropsignup] = useState(false)
	const [modal_backdroppass, setmodal_backdroppass] = useState(false)

	function tog_backdroplogin() {
		history.push(`login/ckbKIPUisO8mOtRL27u`)
	}

	function tog_backdropsignup() {
		setmodal_backdropsignup(true)
	}
	function tog_backdroppass() {
		setmodal_backdroppass(true)
	}

	function handleChangeLogin(value, issignup) {
		setmodal_backdroplogin(value)
		if (issignup) {
			tog_backdropsignup()
		}
	}
	function handlePasswordChange(value, ispasswordC) {
		setmodal_backdroplogin(value)
		if (ispasswordC) {
			tog_backdroppass()
		}
	}

	function handleChangeSignup(value, islogin) {
		setmodal_backdropsignup(value)
		if (islogin) {
			tog_backdroplogin()
		}
	}
	function handleChangeChangePassword(value, islogin) {
		setmodal_backdroppass(value)
		if (islogin) {
			tog_backdroplogin()
		}
	}
	return (
		<div className="front-wrapper">
			<div className="front-header">
				<div className="tph-group">
					<div className="tph-left">
						<a href="#">
							<img src={logo} className="tph-logo" />
						</a>
					</div>
				</div>
			</div>
			<div className="front-content">
				<div className="front-left-content padding-container">
					<h2 className="title-topm text-with-image">
						Like # Fintwit For:
					</h2>
					<h3 className="title-topm">
						Momentum <br />
						swing & <br />
						<div style={{ display: "flex" }}>
							breakout traders.{" "}
							<img src={tick} style={{ width: "100px", height: "100px", marginTop: "-28px" }} />
						</div>
					</h3>
					<div className="fc-loginbtn title-topm">
						<a href="#" onClick={() => { tog_backdroplogin() }}>
							<i className="fa fa-chevron-circle-right" aria-hidden="true"></i>
							Login
						</a>
					</div>
					<p className="title-topm">Beta Version coming Soon...</p>
				</div>
				<div className="front-right-content">
					<div className="frc-image">
						<img src={sideBannerImage} alt="images" width="100%" />
					</div>
				</div>
			</div>
			{modal_backdroplogin ? (
				<Login
					onChange={handleChangeLogin}
					onPasswordChange={handlePasswordChange}
					dataParentToChild={modal_backdroplogin}
				/>
			) : null}
			{modal_backdropsignup ? (
				<Signup
					onChange={handleChangeSignup}
					dataParentToChild={modal_backdropsignup}
				/>
			) : null}
			{modal_backdroppass ? (
				<ChangePassword
					onChange={handleChangeChangePassword}
					dataParentToChild={modal_backdroppass}
				/>
			) : null}
		</div>
	)
}

export default BetaLanding
