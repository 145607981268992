
import React, { useEffect } from "react"
// import { connect } from "react-redux"
import { withRouter, useHistory } from "react-router-dom"

// import { logoutUser } from "../../store/actions"

//redux
// import { useSelector, useDispatch } from "react-redux"

const Logout = () => {
  
  // const dispatch = useDispatch()
  const history = useHistory();

  useEffect(() => {
    if(localStorage.getItem("user"))
    {
      localStorage.clear()
      history.push("/login/ckbKIPUisO8mOtRL27u");
    }    
  }, [])
return <></>
}



export default withRouter((Logout))
