import React, { useState, useEffect } from "react"
import PropTypes from "prop-types"
import { useHistory } from "react-router-dom"
import { Link } from "react-router-dom"
import { Nav, NavItem } from "reactstrap"
import logo from "../../assets/images/logo.jpeg"
import logoDark from "../../assets/images/logo.jpeg"
import { Progress } from "reactstrap"
// Import menuDropdown
import NotificationDropdown from "../CommonForBoth/TopbarDropdown/NotificationDropdown"
import ProfileMenu from "../CommonForBoth/TopbarDropdown/ProfileMenu"
import SearchDropdown from "../../components/CommonForBoth/TopbarDropdown/SearchDropdown"
import axiosHttpMiddelware from "common/axiosHttpMiddelware"
import toastr from "toastr"
import "../../assets/scss/custom/wethemkrt/post-header.scss"
import "../../assets/scss/custom/wethemkrt/editor.scss"
import "../../assets/scss/custom/wethemkrt/common.scss"
import "toastr/build/toastr.min.css"
import Login from "components/CommonForBoth/AuthenticationModel/Login"
import Signup from "components/CommonForBoth/AuthenticationModel/Signup"
import "./Header.scss"

const Header = ({ isMobile, isMenuOpen, toggleMenu }) => {
	const history = useHistory()
	const [activeClass, setActiveClass] = useState("page-topbar")
	const [user, setUser] = useState(null)
	const [menu, setMenu] = useState(false)
	const [isSearch, setSearch] = useState(false)
	const [modal_backdrop, setmodal_backdrop] = useState(false)
	const [kudosCoin, setkudosCoin] = useState({ coincount: 0, progress: "0" })
	const [modal_backdroplogin, setmodal_backdroplogin] = useState(false)
	const [modal_backdropsignup, setmodal_backdropsignup] = useState(false)

	function tog_backdroplogin() {
		setmodal_backdroplogin(true)
	}

	function tog_backdropsignup() {
		setmodal_backdropsignup(true)
	}

	function handleChangeLogin(value, issignup) {
		setmodal_backdroplogin(value)
		if (issignup) {
			tog_backdropsignup()
		}
	}

	function handleChangeSignup(value, islogin) {
		setmodal_backdropsignup(value)
		if (islogin) {
			tog_backdroplogin()
		}
	}

	function tog_backdrop() {
		setmodal_backdrop(true)
	}

	function handleChange(value) {
		setmodal_backdrop(value)
	}

	useEffect(() => {
		if (localStorage.getItem("user")) {
			setkudosCoin({ coincount: 0, progress: "0" })

			let userid = JSON.parse(localStorage.getItem("user"))
			// axios.defaults.headers.common['Authorization'] = userid.accessToken;
			axiosHttpMiddelware
				.post("getKudosCountByUser", { userid: userid.id })
				.then(response => {
					if (
						response.status == 200 &&
						response.data.kudosCoinResponse !== undefined
					) {
						setkudosCoin({
							...kudosCoin,
							coincount: response.data.kudosCoinResponse.kudoscoin,
							progress: response.data.kudosCoinResponse.progress,
						})
					}
				})
				.catch(err => {
					console.log(err)
					toastr.error("Error fetching kudoscoin.", "Error")
				})
		}
	}, [])

	const clickonBlog = () => {
		history.push(`/blog`)
	}

	const clickonWatchlist = () => {
		history.push(`/watchlist`)
	}

	const clickonLeadboard = () => {
		history.push(`/leadboard`)
	}

	useEffect(() => {
		if (localStorage.getItem("user")) {
			setUser(localStorage.getItem("user"))
		}
		window.addEventListener("scroll", () => {
			if (window.scrollY === 0) {
				setActiveClass("page-topbar")
			} else {
				setActiveClass("page-topbar page-topbar-active")
			}
		})
	}, [])

	return (
		<React.Fragment>
			<header id="page-topbar" className={activeClass}>
				<div className="topbar-logo">
					<Link
						to="/feed"
						className="logo logo-dark w-100"
						onClick={() => {
							window.location.href = "/feed"
						}}>
						<span className="logo-sm">
							<img src={logo} alt="" height="40" />
						</span>
						<span className="logo-lg">
							<img src={logoDark} alt="" height="60" />
						</span>
					</Link>
					<div className="mobile-menu-header">
						{isMobile && (
							<button className="menu-toggle" onClick={toggleMenu}>
								{isMenuOpen ? (
									<i class="bx bx-x"></i>
								) : (
									<i class="bx bx-menu"></i>
								)}
							</button>
						)}
					</div>
				</div>
				<div className="topbar-right">
					<Nav className="d-flex justify-content-end header-nav-top header-no-wrap">
						<NavItem style={{ marginRight: "auto" }}>
							<SearchDropdown />
						</NavItem>
						{user && (
							<NavItem>
								<div className="d-flex">
									<div>
										<Progress
											className="top-progress"
											color="primary"
											value={100 - kudosCoin.progress}
										/>
										{kudosCoin.progress < 50 ? (
											<>Top {kudosCoin.progress}% of users</>
										) : (
											<>
												Progress {100 - kudosCoin.progress}% <br /> based on
												kudos coins
											</>
										)}
									</div>
								</div>
							</NavItem>
						)}
						{user ? (
							<NavItem className="nav-link-btn">
								<div className="d-flex justify-content-center align-items-center">
									<div className="px-2">
										<NotificationDropdown />
									</div>
									<span className="dollar-box px-2">
										<i className="bx bx-dollar-circle dollar-icon-uesr"></i>{" "}
										<span className="dollar-count">
											{" "}
											&nbsp;&nbsp;
											{kudosCoin.coincount ?? 0}{" "}
										</span>
									</span>
									<ProfileMenu />
								</div>
							</NavItem>
						) : (
							<NavItem className="me-5">
								<div>
									<button
										className="btn btn-primary"
										onClick={() => {
											tog_backdroplogin()
										}}>
										{" "}
										Login{" "}
									</button>
									<button
										type="button"
										className="btn btn-primary  ms-2"
										onClick={() => {
											tog_backdropsignup()
										}}>
										{" "}
										Sign Up{" "}
									</button>
								</div>
							</NavItem>
						)}
					</Nav>
					{modal_backdroplogin ? (
						<Login
							onChange={handleChangeLogin}
							dataParentToChild={modal_backdroplogin}
						/>
					) : null}
					{modal_backdropsignup ? (
						<Signup
							onChange={handleChangeSignup}
							dataParentToChild={modal_backdropsignup}
						/>
					) : null}
				</div>
			</header>
		</React.Fragment>
	)
}

export default Header
