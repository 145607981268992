import React from "react"
import { useState, useEffect } from "react"
import axiosHttpMiddelware from "common/axiosHttpMiddelware"
import Toaster from "components/Common/Toaster"
import axiosAuthHttpMiddelware from "common/axiosAuthHttpMiddelware"



const FollowBtn = ({ searchUserInfo }) => {
	const [userData, setUserData] = useState(false)
	const [authUserInfo, setAuthUserInfo] = useState(false)
    const [isFollowing, setIsFollowing] = useState(false)

	const isNotFollowing = userId => {
		for (let i = 0; i < authUserInfo.following.length; i++) {
			const element = authUserInfo.following[i]
			if (element.userId == userId) {
				return false
			}
		}
		return true
	}

	const getUserInfo = user => {
		axiosHttpMiddelware
			.post("/usergetbyid", { userid: user.id })
			.then(userResponse => {
				setAuthUserInfo(userResponse.data.userResponse)
			})
	}

    useEffect(() => {
        if (authUserInfo) {
            setIsFollowing(!isNotFollowing(searchUserInfo.id))
        }
    },[authUserInfo])

	useEffect(() => {
		if (localStorage.getItem("user")) {
			let user = JSON.parse(localStorage.getItem("user"))
			setUserData(user)
		}
	}, [])

	useEffect(() => {
		if (userData) {
			getUserInfo(userData)
		}
	}, [userData])

    const followUser = userId => {
		axiosAuthHttpMiddelware
			.post("/followUser", {
				followeruserid: userData.id,
				userid: userId,
			})
			.then(response => {
				Toaster.successToaster(response.data.message, "Success!")
                setIsFollowing(true)
			})
			.catch(err => {
				Toaster.errorToaster(err.response.data.message, "Oops!")
			})
	}
	const unFollowUser = userId => {
		axiosAuthHttpMiddelware
			.post("/unfollowUser", {
				followeruserid: userData.id,
				userid: userId,
			})
			.then(response => {
				Toaster.successToaster(response.data.message, "Success!")
				setIsFollowing(false)
			})
			.catch(err => {
				Toaster.errorToaster(err.response.data.message, "Oops!")
			})
	}
	return (
		<div>
			{searchUserInfo && userData && authUserInfo && (
				<>
					{searchUserInfo.name !== userData.username && (
						<>
							{!isFollowing ? (
								<a
									type="button"
									className="theme-btn pb-btn"
									onClick={() => {
										followUser(searchUserInfo.id)
									}}>
									Follow
								</a>
							) : (
								<a
									type="button"
									className="theme-btn pb-btn"
									onClick={() => {
										unFollowUser(searchUserInfo.id)
									}}>
									Unfollow
								</a>
							)}
						</>
					)}
				</>
			)}
		</div>
	)
}

export default FollowBtn
