import React from "react"
import upArrowGray from "../../../assets/images/up-arrow-gray.svg"
import upArrowAllowed from "../../../assets/images/up-arrow-allowed.svg"
import downArrowGray from "../../../assets/images/down-arrow-gray.svg"
import downArrowAllowed from "../../../assets/images/down-arrow-allowed.svg"

const Vote = ({ element, isUpVoteAllowed, isDownVoteAllowed, getUpvoteIcon, upVote, key, userData, showVote, getDownvoteIcon, addedVote }) => {
	return (
		<div className="vote-btn">
			<div className="up-vote-btn">
				<img
					style={{ width: 20 }}
					className={isUpVoteAllowed(element, userData, key) ? "" : "disabled"}
					onClick={e => {
						e.preventDefault()
						if (isUpVoteAllowed(element, userData, key)) {
							upVote(key, element.postguid, true)
						}
					}}
					role="button"
					src={getUpvoteIcon(element, userData, key)}
					onMouseOver={e => {
						if (isUpVoteAllowed(element, userData, key)) {
							e.currentTarget.src = upArrowAllowed
						}
					}}
					onMouseOut={e => {
						if (isUpVoteAllowed(element, userData, key)) {
							e.currentTarget.src = upArrowGray
						}
					}}
				/>
			</div>
			{showVote && (
				<div className="vote-text">{addedVote(element.votes, key)}</div>
			)}
			<div className="down-vote-btn">
				<img
					style={{ width: 20 }}
					className={
						isDownVoteAllowed(element, userData, key) ? "" : "disabled"
					}
					onClick={e => {
						e.preventDefault()
						if (isDownVoteAllowed(element, userData, key)) {
							upVote(key, element.postguid, false)
						}
					}}
					role="button"
					src={getDownvoteIcon(element, userData, key)}
					onMouseOver={e => {
						if (isDownVoteAllowed(element, userData, key)) {
							e.currentTarget.src = downArrowAllowed
						}
					}}
					onMouseOut={e => {
						if (isDownVoteAllowed(element, userData, key)) {
							e.currentTarget.src = downArrowGray
						}
					}}
				/>
			</div>
		</div>
	)
}

export default Vote
