import React from 'react'
import bearImage from "assets/images/bear.png"
import bullImage from "assets/images/bull.png"

const Sentiment = ({ sentimentValue }) => {
    return sentimentValue > 0 ? (
        <button className="btn-icon-sentiment">
            <div className=" px-2">
                {" "}
                + {sentimentValue}{" "}
                <img
                    src={bullImage}
                    height="25"
                    width="25"
                    className="text-end"></img>
            </div>
        </button>
    ) : (
        <button className="btn-icon-sentiment">
            <div className=" px-2">
                {" "}
                {sentimentValue}{" "}
                <img
                    src={bearImage}
                    height="25"
                    width="25"
                    className="text-end"></img>
            </div>
        </button>
    )
}

export default Sentiment