import React from "react"
import bearImage from "../../assets/images/bear.png"
import bullImage from "../../assets/images/bull.png"
import "./PostFooter.scss"
import ReplyBox from "./ReplyBox"
import Toaster from "./Toaster"
import Vote from "./Vote/Vote"
import Sentiment from "./Sentiment"
const PostFooter = ({
	element,
	userInfo,
	isUpVoteAllowed,
	isDownVoteAllowed,
	getUpvoteIcon,
	upVote,
	key,
	userData,
	showVote,
	getDownvoteIcon,
	addedVote,
}) => {
	const [showComment, setShowComment] = React.useState(false)
	return (
		<>
			<div className="tool-bar-btn">
				<div className="flex-ver-center">
					<Vote
						element={element}
						isUpVoteAllowed={isUpVoteAllowed}
						isDownVoteAllowed={isDownVoteAllowed}
						getUpvoteIcon={getUpvoteIcon}
						upVote={upVote}
						key={key}
						userData={userInfo}
						showVote={showVote}
						addedVote={addedVote}
						getDownvoteIcon={getDownvoteIcon}
					/>
				</div>
				<div className="flex-ver-center">
					<div
						className="reply-btn"
						onClick={() => {
							navigator.clipboard.writeText(
								`${window.location.origin}/post/${element.postguid}`
							)
							Toaster.successToaster("Link Copied!", "Success")
						}}
						role="button"
						title="Copy Link">
						<i className="bx bx-copy" style={{ marginTop: 0 }}></i>
					</div>
					{element.isnews ? (
						<div className="bt-lable">
							<label className="btn-sm" onClick={e => e.preventDefault()}>
								#news
							</label>
						</div>
					) : null}

					{element.isfundamental && element.istechnical ? (
						<div className="hover-toggle">
							<div className="show-on-hover">
								<div className="bt-lable">
									<label
										className="btn-sm-type"
										onClick={e => e.preventDefault()}>
										#both
									</label>
								</div>
							</div>
							<div className="hide-on-hover">
								<div className="bt-lable">
									<label
										className="btn-sm-type"
										onClick={e => e.preventDefault()}>
										#fundamental
									</label>
								</div>
								<div className="bt-lable">
									<label
										className="btn-sm-type"
										onClick={e => e.preventDefault()}>
										#technical
									</label>
								</div>
							</div>
						</div>
					) : element.isfundamental ? (
						<div className="bt-lable">
							<label className="btn-sm-type" onClick={e => e.preventDefault()}>
								#fundamental
							</label>
						</div>
					) : element.istechnical ? (
						<div className="bt-lable">
							<label className="btn-sm-type" onClick={e => e.preventDefault()}>
								#technical
							</label>
						</div>
					) : null}
				</div>
				<div className="flex-ver-center">
					<Sentiment sentimentValue={element.sentimentvalue} />
				</div>
				<div className="flex-ver-center" title="Comments">
					<span
						role="button"
						onClick={() => {
							setShowComment(!showComment)
						}}>
						{element.ccount}
						<i class="bx bx-chevron-down"></i>
					</span>
				</div>
			</div>
			{showComment && (
				<div>
					<ReplyBox element={element} userInfo={userInfo} />
				</div>
			)}
		</>
	)
}

export default PostFooter
